import React, { Component } from "react";
import { Alert, Modal } from "react-bootstrap";
import { API, Auth, auth0SignInButton } from "aws-amplify";
import StopWatch from "../StopWatch/StopWatch";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import "./Board.css";

function propertyExists(key, prop, arr) {
  return arr.some(function (el) {
    return el[key] === prop;
  });
}

class Board extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: null,
      blogInfo: "",
      done: false,
      modal: false,
      board: [],
      pieces: null,
      timer: true,
      infoModal: false,
      infoService: "",
      infoBody: "",
      infoDoc: "",
      info: "No Icons Selected",
      selected: false,
      timeToCompletion: null,
      numOfMoves: 0,
      hintsUsed: 0,
      score: 0,
      moves: [],
      feedback: "",
    };
  }

  componentDidMount() {
    let board = [];
    let board_indices = [];
    this.props.puzzle.solution.forEach((el) => {
      if (el.correct.length > 1) {
        el.correct.forEach((coordinate) => {
          let boardObj = {};
          if (!board_indices.includes(coordinate)) {
            board_indices.push(coordinate);
            boardObj["coordinate"] = coordinate;
            boardObj["image"] = "blank";
            boardObj["name"] = "";
            boardObj["border"] = "rgb(58, 96, 143)";
            board.push(boardObj);
          }
        });
      } else {
        if (!board_indices.includes(el.correct[0])) {
          let boardObj = {};
          board_indices.push(el.correct[0]);
          boardObj["coordinate"] = el.correct[0];
          boardObj["image"] = "blank";
          boardObj["name"] = "";
          boardObj["border"] = "rgb(58, 96, 143)";
          board.push(boardObj);
        }
      }
    });

    this.setState({
      board: board,
      timer: true,
      domain: this.props.puzzle.domainId,
      pieces: this.props.puzzle.pieces_extra.sort(() => Math.random() - 0.5),
    });
  }

  handleClose = () => {
    this.setState({ complete: null });
  };

  handleCloseInfoModal = () => {
    this.setState({ infoModal: false, infoBody: "", infoService: "" });
  };

  handleTimer = () => {
    this.setState({ timerStart: true });
  };

  setTimeToCompletion = (seconds) => {
    this.setState({
      timeToCompletion: seconds,
    });
  };

  onClickShowModal = (e, name, description, doc) => {
    var text = `${name.toUpperCase()}: ${description} `;
    this.setState((prevState) => ({
      infoBody: description,
      infoService: name,
      infoDoc: doc,
      infoModal: !prevState.infoModal,
      info: text,
      selected: true,
    }));
  };

  onDragStart = (e, name, image, coordinate) => {
    e.dataTransfer.setData("name", name);
    e.dataTransfer.setData("image", image);
    e.dataTransfer.setData("current", coordinate);
  };

  onDragOver = (e) => {
    e.preventDefault();
  };

  onDrop = (e, coordinate) => {
    e.preventDefault();
    const name = e.dataTransfer.getData("name");
    const image = e.dataTransfer.getData("image");
    const previous = e.dataTransfer.getData("current");

    let board = this.state.board;
    let numOfMoves = this.state.numOfMoves;
    let old_index = board.findIndex(
      (el) => el.coordinate === parseInt(previous)
    );
    let new_index = board.findIndex((el) => el.coordinate === coordinate);

    if (old_index !== -1) {
      board[old_index].name = "";
      board[old_index].image = "blank";
    }
    if (old_index !== new_index) {
      board[new_index].name = name;
      board[new_index].image = image;
      numOfMoves += 1;
    } else {
      board[new_index].name = name;
      board[new_index].image = image;
    }

    var solution = this.props.puzzle.solution;
    var moveNumber = this.state.numOfMoves;
    var moveNumber = moveNumber + 1;
    //console.log("Dropping "+name);

    let result;
    let correctName;

    solution.forEach((item) => {
      if (item.correct.includes(coordinate)) {
        correctName = item.name;
      }
    });

    if (name === correctName) {
      //console.log("result: "+"Correct");
      result = "Yes";
    } else {
      //console.log("result: "+"Wrong");
      result = "No";
    }

    var myMoves = this.state.moves;
    var move = {
      moveNumber: moveNumber,
      dropped: name,
      correctName: correctName,
      correctSelection: result,
      moveType: "Dropping Piece",
      time: Date.now(),
    };
    myMoves.push(move);

    this.setState({
      ...this.state,
      board,
      numOfMoves: moveNumber,
      moves: myMoves,
    });

    // this.checkPuzzleState();
  };

  checkHints = () => {
    let localBoard = this.state.board;
    let solution = this.props.puzzle.solution;
    let myhints = this.state.hintsUsed;

    let puzzlePiecesCountMap = {};

    myhints = myhints + 1;
    for (let i = 0; i < localBoard.length; i++) {
      if (solution.some((el) => el.name === localBoard[i].name)) {
        let index = solution.findIndex((el) => el.name === localBoard[i].name);
        if (solution[index].correct.includes(localBoard[i].coordinate)) {
          if (!puzzlePiecesCountMap.hasOwnProperty(localBoard[i].name)) {
            puzzlePiecesCountMap[localBoard[i].name] = 1;
            if (
              solution[index].count >= puzzlePiecesCountMap[localBoard[i].name]
            ) {
              localBoard[i].border = "#00C853";
            }
          } else {
            puzzlePiecesCountMap[localBoard[i].name] += 1;
            if (
              solution[index].count >= puzzlePiecesCountMap[localBoard[i].name]
            ) {
              localBoard[i].border = "#00C853";
            } else {
              localBoard[i].border = "red";
            }
          }
        } else {
          localBoard[i].border = "red";
        }
      } else {
        localBoard[i].border = "red";
      }
    }

    var myMoves = this.state.moves;
    var moveNumber = this.state.numOfMoves;
    moveNumber = moveNumber + 1;
    var hintMove = {
      moveNumber: moveNumber,
      moveType: "Hint",
      time: Date.now(),
    };
    myMoves.push(hintMove);
    this.setState({
      board: localBoard,
      hintsUsed: myhints,
      numOfMoves: moveNumber,
      moves: myMoves,
    });

    setTimeout(() => {
      for (let i = 0; i < localBoard.length; i++) {
        localBoard[i].border = "rgb(58, 96, 143)";
      }
      this.setState({
        board: localBoard,
      });
    }, 1500);
  };

  checkPuzzleState = () => {
    let localBoard = this.state.board;
    let solution = this.props.puzzle.solution;
    let myComplete = false;
    let myTimer = true;
    var numCorrect = 0;
    var myScore = 0;

    let puzzlePiecesCountMap = {};

    for (let i = 0; i < localBoard.length; i++) {
      let piece_name = localBoard[i].name;
      let local_piece_coordinate = localBoard[i].coordinate;
      if (propertyExists("name", piece_name, solution)) {
        let found = solution.find((element) => element.name === piece_name);
        if (found.correct.includes(local_piece_coordinate)) {
          if (!puzzlePiecesCountMap.hasOwnProperty(piece_name)) {
            puzzlePiecesCountMap[piece_name] = 1;
            numCorrect += 1;
          } else {
            puzzlePiecesCountMap[piece_name] += 1;
            numCorrect += 1;
          }
        } else {
          this.setState({ complete: false });
          break;
        }
      }
    }

    if (numCorrect === localBoard.length) {
      for (let i = 0; i < solution.length; i++) {
        let piece_name = solution[i].name;
        let correct_piece_count = solution[i].count;
        if (!puzzlePiecesCountMap.hasOwnProperty(piece_name)) {
          break;
        } else {
          if (puzzlePiecesCountMap[piece_name] !== correct_piece_count) {
            break;
          } else {
            if (i === solution.length - 1) {
              myTimer = false;
              myComplete = true;
              if (this.state.done === false) {
                this.calculateScore();
                this.setState({ done: true });
              }
            }
          }
        }
      }
    }
    return myComplete;
  };

  passGameSummaryToBackend = async () => {
    var signedInUser = await Auth.currentAuthenticatedUser();
    var myScore = this.state.score;
    let myInit = {
      // OPTIONAL
      body: {
        userId: signedInUser.username,
        puzzleId: this.props.puzzle.puzzleId,
        score: myScore,
        hints: this.state.hintsUsed,
        totalTimeToComplete: this.state.timeToCompletion,
        moves: this.state.moves,
        feedback: this.state.feedback,
      }, // replace this with attributes you need
      headers: {
        Authorization: `Bearer ${signedInUser.signInUserSession.idToken.jwtToken}`,
      },
    };
    console.log(myInit)
    return await API.post("Prod", `/puzzlescores`, myInit);
  };

  updateUserScore = async (score) => {
    var signedInUser = await Auth.currentAuthenticatedUser();
    let myInit = {
      // OPTIONAL
      body: {
        username: signedInUser.username,
        score: score,
      }, // replace this with attributes you need
      headers: {
        Authorization: `Bearer ${signedInUser.signInUserSession.idToken.jwtToken}`,
      },
    };
    return await API.post("Prod", `/leaderboard/update-score`, myInit);
  };

  submitScore = async () => {
    //userid,puzzleid,score,hints,time,moves_array,
    //  /puzzlescores POST
    this.handleClose();
    var tempScore = this.state.score;
    try {
      var res = await this.passGameSummaryToBackend();
      //console.log(res);
      tempScore = res.totalScore;
    } catch (e) {
      console.log(e.message);
      alert(e.message);
      return;
    }
    this.updateUserScore(tempScore);
  };

  updateFeedback = (evt) => {
    var enteredFeedback = evt.target.value;
    this.setState({ feedback: enteredFeedback });
  };

  renderFreePiecesContainer(pieces) {
    let free = [];
    pieces.forEach((piece, index) => {
      free.push(
        <div
          key={index}
          id={index}
          className="free-piece"
          onMouseDown={(e) =>
            this.onClickShowModal(e, piece.name, piece.desc, piece.doc_url)
          }
          draggable
          onDragStart={(e) => this.onDragStart(e, piece.name, piece.image)}
        >
          <img src={piece.image} alt={piece.name} />
          <span>{piece.name}</span>
        </div>
      );
    });
    const style = {
      width: 45 * `${pieces.length}`,
    };
    return (
      <div className="free" style={style}>
        {free}
      </div>
    );
  }

  renderPuzzleBoardContainer(board, size) {
    const boardStyle = {
      backgroundImage: `url(${this.props.puzzle.diag})`,
      gridTemplateColumns: "auto ".repeat(`${size[1] + 1}`),
      width: 60 * `${size[1] + 1}`,
      height: 60 * `${size[0] + 1}`,
      border: "0.5px solid black",
    };

    let puzzle = [];
    for (let i = 0; i < (size[0] + 1) * size[1]; i++) {
      let piece = board.find((el) => el.coordinate === i);
      if (piece !== undefined) {
        if (piece.image === "blank") {
          puzzle.push(
            <div
              className="grid-item"
              id={i}
              key={i}
              onDrop={(e) => this.onDrop(e, i)}
              onDragOver={(e) => this.onDragOver(e)}
              style={{
                borderColor: "rgb(58, 96, 143)",
                borderWidth: "1px",
              }}
            ></div>
          );
        } else {
          puzzle.push(
            <div
              className="grid-item"
              id={i}
              key={i}
              draggable
              onDrop={(e) => this.onDrop(e, i)}
              onDragOver={(e) => this.onDragOver(e)}
              onDragStart={(e) =>
                this.onDragStart(e, piece.name, piece.image, i)
              }
              style={{
                borderColor: piece.border,
                borderStyle: "5px solid",
                borderWidth: "3px",
                boxShadow: "2.5px 2.5px 5px #696969",
              }}
            >
              {piece.image === "blank" ? null : (
                <img alt={i} src={piece.image}></img>
              )}
            </div>
          );
        }
      } else {
        puzzle.push(
          <div
            className="grid-item-empty"
            id={i}
            key={i}
            style={{ borderColor: "rgb(58, 96, 143)" }}
          ></div>
        );
      }
    }

    return (
      <div className="grid-board" style={boardStyle}>
        {puzzle}
      </div>
    );
  }

  renderLearnMoreButton = () => {
    if (this.state.selected === true) {
      return (
        <a href={this.state.infoDoc} target="#">
          Learn more
        </a>
      );
    } else {
      return;
    }
  };

  handleSubmit = () => {
    const completionState = this.checkPuzzleState();
    this.setState({ complete: completionState });
  };

  stopClock = () => {
    this.setState({
      timer: false,
    });
  };

  calculateScore = async () => {
    await this.stopClock();
    //console.log(this.props.puzzle);
    var myBlogInfo;
    if (
      this.props.puzzle.Blog_URL === undefined ||
      this.props.puzzle.Blog_URL === "" ||
      this.props.puzzle.Blog_URL === null
    ) {
      myBlogInfo = "";
    } else {
      myBlogInfo = ` Learn more <a target=# href=${this.props.puzzle.Blog_URL}>here</a>.`;
    }

    setTimeout(() => {
      console.log("Time to complete is ",this.state.timeToCompletion)
      var myScore =
        3000 - this.state.timeToCompletion - 10 * this.state.hintsUsed;
      this.setState({ score: myScore, complete: true, blogInfo: myBlogInfo });
    }, 50);
  };

  render() {
    return (
      <div className="board-container">
        {/*this.state.complete */}
        <Modal show={this.state.complete} animation={false}>
          <Modal.Body>
            <div className="modal-alert">
              <Alert variant="success" onClose={this.handleClose}>
                {" "}
                Congratz you completed the puzzle!
              </Alert>
              <div className="board_score">Score: {this.state.score}</div>
              <hr />
              <div>
                <div className="Board_overview_header">Solution Overview</div>
                {this.props.puzzle.Arch_Description}
                {ReactHtmlParser(this.state.blogInfo)}
                <img
                  src={this.props.puzzle.solution_diag}
                  style={{ width: "100%" }}
                />
              </div>
              <hr />
              <div className="Board_overview_header">Feedback</div>
              <div>
                Please provide feedback below for this architecture. Have you
                used this architecture? Are there better alternatives?
              </div>
              <textarea
                axlength="400"
                className="board_feedback_prompt"
                value={this.state.feedback}
                onChange={this.updateFeedback}
              ></textarea>
              <Link to="/puzzles">
              <button
                className="board_submit_button"
                onClick={() => this.submitScore()}
              >
                Submit
              </button>
              </Link>
              {/* <button
                className="board_close_button"
                onClick={() => this.handleClose()}
              >
                Close
              </button> */}
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.complete === false} animation={false}>
          <Modal.Body>
            <div className="modal-alert">
              <Alert variant="danger" onClose={this.handleClose}>
                {" "}
                Not quite there yet...
              </Alert>
              <button
                className="board_close_button"
                onClick={() => this.handleClose()}
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <div className="board">
          <p>
            <b>Problem Statement:</b> {this.props.puzzle.description}
          </p>
          <div className="random">
            <StopWatch
              isActive={this.state.timer}
              setTimeToComplete={this.setTimeToCompletion}
            />
            <button
              className="hint-button"
              onClick={() => {
                this.checkHints();
              }}
            >
              HINTS
            </button>
            <button
              className="submit-button"
              onClick={() => this.handleSubmit()}
            >
              SUBMIT
            </button>
          </div>
          {this.renderPuzzleBoardContainer(
            this.state.board,
            this.props.puzzle.size
          )}
          {/* {this.props.difficulty == "beginner"
            ? this.renderFreePiecesContainer(this.props.puzzle.pieces)
            : this.props.puzzle.pieces_extra
            ? this.renderFreePiecesContainer(this.props.puzzle.pieces_extra)
            : null} */}
          {this.state.pieces !== null
            ? this.renderFreePiecesContainer(this.state.pieces)
            : null}
          <div className="context_block">
            {this.state.info}
            {this.renderLearnMoreButton()}
          </div>
        </div>
      </div>
    );
  }
}

export default Board;
