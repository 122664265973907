import Board from "../Board/Board";
import "./Game.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

function Game(props) {
  const [puzzle, setPuzzle] = useState(null);
  const [difficulty, setDifficulty] = useState(null);
  const [session, setSession] = useState(false);
  const [showDescription, setShowDescription] = useState(true);

  const handleShowDescription = () => {
    setShowDescription(!showDescription);
  };

  useEffect(() => {
    if (props.location.state !== undefined) {
      setPuzzle(props.location.state.puzzle);
      setSession(props.location.state.session);
      setDifficulty(props.location.state.difficulty);
    }
  }, [props.location.state]);

  return (
    <div>
      {session === false ? (
        <div className="session">
          <p>NO GAME SESSION</p>
          <Link to="/puzzles">
            <button className="puzzles-button">CHOOSE A PUZZLE</button>
          </Link>
        </div>
      ) : showDescription ? (
        <div className="difficulty-container">
          <div className="difficulty-options bouncy">
            <p>Problem Statement: {puzzle.description}</p>
            <button
              className="difficulty-button"
              onClick={handleShowDescription}
            >
              START
            </button>
          </div>
        </div>
      ) : null}
      {!showDescription ? (
        <div>
          <Board puzzle={puzzle} difficulty={difficulty} />
        </div>
      ) : null}

      {}
    </div>
  );
}

export default Game;
