import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import "./ProfilePage.css";
import { API, Auth, auth0SignInButton } from "aws-amplify";
import { PieChart } from "react-minimal-pie-chart";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import logo from "../../images/aws-arch-puzzle-v1.jpg";

function ProfilePage(props) {
  const username = props.user.username;
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      var signedInUser = await Auth.currentAuthenticatedUser();
      let myInit = {
        headers: {
          Authorization: `Bearer ${signedInUser.signInUserSession.idToken.jwtToken}`,
        },
      };
      const userProfile = await API.get(
        "Prod",
        `/userprofiles?userId=${username}`,
        myInit
      );
      setUserProfile(userProfile[0]);
    };
    fetchUserProfile();
  }, [username]);

  const renderPieChart = (scoreByDomain) => {
    let data = [];
    let colors = ["#173F5F", "#20639B", "#3CAEA3", "#F6D55C", "#ED553B"];
    Object.keys(scoreByDomain).forEach((item, index) => {
      data.push({
        title: item,
        value: scoreByDomain[item],
        color: colors[index],
      });
    });

    data["AI/ML"] = scoreByDomain["aiml"];
    data["Serverless"] = scoreByDomain["serverless"];
    data["Web Application"] = scoreByDomain["webapp"];
    data["IoT"] = scoreByDomain["iot"];
    data["Analytics"] = scoreByDomain["analytics"];

    return (
      <div>
        <PieChart
          style={{ height: "300px" }}
          radius={40}
          label={({ dataEntry }) => dataEntry.title}
          labelStyle={{
            fontSize: "5px",
            fontFamily: "sans-serif",
          }}
          labelPosition={112}
          lineWidth={15}
          paddingAngle={0}
          data={data}
        ></PieChart>
      </div>
    );
  };

  const renderScoreBoard = (scoreByPuzzle) => {
    let scoreByPuzzleArr = [];
    for (var key of Object.keys(userProfile.scoreByPuzzle)) {
      const domain = key.split("-")[0];
      scoreByPuzzleArr.push({
        PuzzleId: key,
        Score: userProfile.scoreByPuzzle[key]["score"],
        Domain: domain,
      });
    }
    return (
      <div>
        <table>
          <tbody>
            <tr key={uuid()}>
              <th key={uuid()}>Puzzle Name</th>
              <th key={uuid()}>Domain</th>
              <th key={uuid()}>Score</th>
            </tr>
            {scoreByPuzzleArr.map((item, index) => (
              <tr key={uuid()}>
                <td key={uuid()}>{item.PuzzleId}</td>
                <td key={uuid()}>{item.Domain}</td>
                <td key={uuid()}>{item.Score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderProfilePage = () => {
    return (
      <div className="profile-page">
        <div className="profile-header">
          <h2>Hey {props.user.username}, welcome back!</h2>
          <img className="avatar-image" src={logo}></img>
        </div>
        <Link to="/puzzles">
          <Button> PLAY NOW</Button>
        </Link>
        <div className="profile-section">
          <div className="profile-scores">
            <h5>
              Puzzles Played: <b>{userProfile.totalNumberOfGamesPlayed}</b>
            </h5>
            {userProfile.scoreByDomain !== undefined
              ? renderPieChart(userProfile.scoreByDomain)
              : null}
          </div>
          <div className="profile-scoreboard">
            <h5>Scores Board</h5>
            {userProfile.scoreByPuzzle !== undefined
              ? renderScoreBoard(userProfile.scoreByPuzzle)
              : null}
          </div>
        </div>
      </div>
    );
  };

  return <div>{userProfile !== null ? renderProfilePage() : null}</div>;
}

export default ProfilePage;
