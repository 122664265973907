import React from "react";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import PuzzlesPage from "./components/PuzzlesPage/PuzzlesPage";
import WelcomePage from "./components/WelcomePage/WelcomePage";
import LeaderBoard from "./components/Leaderboard/Leaderboard";
import PuzzleBank from "./components/PuzzleBank/PuzzleBank";
import Game from "./components/Game/Game";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./AppWithAuth.css";
import Amplify from "aws-amplify";
import { AmplifyAuthenticator } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:98780d31-d4f4-481a-8121-c1105e35f410",
    region: "us-east-1",
    userPoolId: "us-east-1_n35FmfZvK",
    userPoolWebClientId: "ipe051vif225tgdomlj15nmmj",
  },
  API: {
    endpoints: [
      {
        name: "Prod",
        endpoint: "https://87fohldzqc.execute-api.us-east-1.amazonaws.com/prod",
      },
    ],
  },
});

const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState(null);
  const [welcomePage, setWelcomePage] = React.useState(true);
  const [user, setUser] = React.useState();

  const handleWelcomePage = () => {
    setWelcomePage(false);
  };

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      if (nextAuthState === "signup") {
        //add the userinfo to DynamoDB
      }
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return (
    <div>
      {welcomePage === true && authState === AuthState.SignIn ? (
        <WelcomePage handleWelcomePage={handleWelcomePage} />
      ) : authState === AuthState.SignedIn && user ? (
        <div>
          <Router>
            <NavigationBar />
            <Switch>
              <Route exact path="/">
                <ProfilePage user={user} />
              </Route>
              <Route path="/puzzles">
                <PuzzlesPage />
              </Route>
              <Route path="/puzzlebank">
                <PuzzleBank />
              </Route>
              <Route path="/leaderboard">
                <LeaderBoard  user={user} />
              </Route>
              <Route path="/game" render={(props) => <Game {...props} />} />
            </Switch>
          </Router>
        </div>
      ) : (
        <AmplifyAuthenticator />
      )}
    </div>
  );
};

export default AuthStateApp;
