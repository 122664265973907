import React, { useState, useEffect } from "react";
import Loading from "../Loading/Loading";
import "./PuzzleBank.css";
import { API } from "aws-amplify";

function PuzzleBank() {
  const [puzzleBank, setPuzzleBank] = useState(null);

  useEffect(() => {
    const fetchPuzzleBank = async () => {
      const puzzleBank = await API.get("Prod", `/puzzlebank`);
      setPuzzleBank(puzzleBank);
    };
    fetchPuzzleBank();
  }, []);

  const renderPuzzles = (puzzles) => {
    let webapp_puzzles = [];
    let ml_puzzles = [];
    let iot_puzzles = [];
    let serverless_puzzles = [];
    let analytic_puzzles = [];
    console.log(puzzles);
    puzzles.forEach((puzzle, index) => {
      if (puzzle.domainId === "webapp") {
        webapp_puzzles.push(
          <div className="puzzle" key={index} id={index}>
            <h3>{puzzle.puzzleId}</h3>
            <span>{puzzle.description}</span>
            <h3>{puzzle.puzzleId}</h3>
            <img src={puzzle.diag}></img>
          </div>
        );
      } else if (puzzle.domainId === "analytics") {
        analytic_puzzles.push(
          <div className="puzzle" key={index} id={index}>
            <h3>{puzzle.puzzleId}</h3>
            <span>{puzzle.description}</span>
            <img src={puzzle.diag}></img>
          </div>
        );
      } else if (puzzle.domainId === "serverless") {
        serverless_puzzles.push(
          <div className="puzzle" key={index} id={index}>
            <h3>{puzzle.puzzleId}</h3>
            <span>{puzzle.description}</span>
            <img src={puzzle.diag}></img>
          </div>
        );
      } else if (puzzle.domainId === "aiml") {
        ml_puzzles.push(
          <div className="puzzle" key={index} id={index}>
            <h3>{puzzle.puzzleId}</h3>
            <span>{puzzle.description}</span>
            <img src={puzzle.diag}></img>
          </div>
        );
      } else if (puzzle.domainId === "iot") {
        iot_puzzles.push(
          <div className="puzzle" key={index} id={index}>
            <h3>{puzzle.puzzleId}</h3>
            <span>{puzzle.description}</span>
            <img src={puzzle.diag}></img>
          </div>
        );
      }
    });
    return (
      <div className="puzzle-bank">
        <h4>WebApp</h4>
        {webapp_puzzles}
        <h4>AI/ML</h4>
        {ml_puzzles}
        <h4>IOT</h4>
        {iot_puzzles}
        <h4>SERVRERLESS</h4>
        {serverless_puzzles}
        <h4>ANALYTICS</h4>
        {analytic_puzzles}
      </div>
    );
  };

  return (
    <div>{puzzleBank !== null ? renderPuzzles(puzzleBank) : <Loading />}</div>
  );
}

export default PuzzleBank;
