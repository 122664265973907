import React from 'react';
import './Leaderboard.css';
import { Link} from 'react-router-dom';
import { API,Auth } from "aws-amplify";

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scores : [],
    };
  }

  getLeaderboard = async ()=>{
    var user = await Auth.currentAuthenticatedUser();
    let apiName = 'Prod';
    let path = '/leaderboard/get-leaderboard';
    let myInit = { // OPTIONAL
        body: {
            "user":this.props.user.username,
            "numberOfRanks":100
        }, // replace this with attributes you need
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
    }
    return await API.post(apiName, path,myInit);
  }

  componentDidMount(){
    this.getLeaderboard().then((res)=>{
      //console.log(res);
      this.setState({scores:res});
    });
  }

  
  render() {
    var myscores = this.state.scores
    return (
      <div className="leaderboard">
        <h2>Leaderboard</h2>
        <table>
          <thead>
            <tr><th>Player</th><th>Score</th></tr>
          </thead>
          <tbody>
          {this.state.scores.map((el, index) => 
          <tr key={index}>
            <td className="userName"><Link to="/">{el[0]}</Link></td>
            <td>{el[1]}</td>
          </tr>
        )}
          </tbody>
        </table>
      
      </div>
    );
  }
}

export default Leaderboard;
