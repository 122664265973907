import React from "react";
import "./Puzzle.css";
import { Link } from "react-router-dom";
import { Fragment } from "react";

function Puzzle(props) {
  const style = {
    width: 240,
    height: 80,
    fontSize: "13px",
  };
  return (
    <Link
      to={{
        pathname: "/game",
        state: {
          puzzle: props.puzzle,
          difficulty: props.difficulty,
          session: true,
        },
      }}
    >
      <button className="puzzles-button" key={props.index} style={style}>
        <div className="puzzle">{props.puzzle.puzzle_name.substr(0, 50)}</div>
      </button>
    </Link>
  );
}

export default Puzzle;
