import React from "react";
import "./WelcomePage.css";

function WelcomePage(props) {
  return (
    <div className="homepage">
      <section className="b1">
        <div className="in1">
          <div className="content">
            <h1>Welcome to Architecture Puzzle</h1>
            <button className="btn" onClick={props.handleWelcomePage}>
              {" "}
              Login | Sign Up
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WelcomePage;
