import React from 'react';
import ReactLoading from 'react-loading';
import './Loading.css';

const Loading = ({ type, color }) => (
  <div className="loading">
    <ReactLoading
      className="loading-animation"
      type={type}
      color={color}
      height={'0%'}
      width={'25%'}
    />
  </div>
);

export default Loading;
