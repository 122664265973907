import React, { useState } from "react";
import Puzzle from "../Puzzle/Puzzle";
import Loading from "../Loading/Loading";
import "./PuzzlesPage.css";
import { API, Auth } from "aws-amplify";

function PuzzlesPage() {
  const [topic, setTopic] = useState(null);
  const [difficulty, setDifficulty] = useState("intermediate");
  const [puzzles, setPuzzles] = useState(null);

  // const handleDifficultyChange = (difficulty) => {
  //   setDifficulty(difficulty);
  // };

  const handleTopicChange = (topic) => {
    setTopic(topic);
  };

  const fetchPuzzles = async (topic) => {
    var signedInUser = await Auth.currentAuthenticatedUser();
    let myInit = {
      headers: {
        Authorization: `Bearer ${signedInUser.signInUserSession.idToken.jwtToken}`,
      },
    };

    const puzzles = await API.get(
      "Prod",
      `/adminpuzzles?domain=${topic}&username=${signedInUser.username}`,
      myInit
    );
    if (topic === "aiml") {
      topic = "ai/ml";
    } else if (topic == "webapp") {
      topic = "web-app";
    }
    setTopic(topic);
    setPuzzles(puzzles);
  };

  return (
    <div className="puzzles-container">
      <div className="puzzles-options bouncy">
        {topic === null && difficulty != null ? (
          <div>
            <h1>SELECT YOUR DOMAIN:</h1>

            <button
              className="puzzles-button"
              name="serverless"
              onClick={() => fetchPuzzles("serverless")}
            >
              SERVERLESS
            </button>
            <button
              className="puzzles-button"
              name="analytics"
              onClick={() => fetchPuzzles("analytics")}
            >
              ANALYTICS
            </button>
            <button
              className="puzzles-button"
              name="webapp"
              onClick={() => fetchPuzzles("webapp")}
            >
              WEB APP
            </button>
            <button
              className="puzzles-button"
              name="iot"
              onClick={() => fetchPuzzles("iot")}
            >
              IOT
            </button>
            <button
              className="puzzles-button"
              name="aiml"
              onClick={() => fetchPuzzles("aiml")}
            >
              ML
            </button>
          </div>
        ) : null}
        {/* {topic !== null && difficulty == null ? (
          <div>
            <h1>SELECT YOUR DIFFICULTY:</h1>
            <button
              className="puzzles-button"
              name="beginner"
              onClick={() => handleDifficultyChange("beginner")}
            >
              BEGINNER
            </button>
            <button
              className="puzzles-button"
              name="intermediate"
              onClick={() => handleDifficultyChange("intermediate")}
            >
              INTERMEDIATE
            </button>
          </div>
        ) : null} */}
        {topic !== null && difficulty !== null ? (
          <div>
            {puzzles !== null ? (
              <div>
                <h1>
                  SELECT YOUR <span>{topic}</span> PUZZLE:
                </h1>
                {puzzles.map((puzzle, index) => (
                  <Puzzle
                    puzzle={puzzle}
                    index={index}
                    key={index}
                    difficulty={difficulty}
                  ></Puzzle>
                ))}
                <div>
                  <button
                    className="puzzles-button"
                    onClick={() => handleTopicChange(null)}
                  >
                    GO BACK
                  </button>
                </div>
              </div>
            ) : (
              <Loading type={"bars"} color={"black"} />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default PuzzlesPage;
