import React from 'react';
import './NavigationBar.css';
import { Link} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faPuzzlePiece,
  faUser,
  faGamepad,
  faTrophy,
  faSignOutAlt,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';

library.add(fab, faUser, faPuzzlePiece, faGamepad, faTrophy, faSignOutAlt,faQuestionCircle);

function NavigationBar() {
  return (
    <div className="navigation-bar">
      <header>
        <div className="inner-width">
          <i className="menu-toggle-btn fas fa-bars"></i>
          <nav className="navigation-menu">
            <Link to="/">
              <FontAwesomeIcon className="profile-icon" icon="user" /> Profile
            </Link>
            <Link to="/puzzles">
              <FontAwesomeIcon className="puzzle-icon" icon="puzzle-piece" />{' '}
              Puzzles
            </Link>
            <Link to="/leaderboard">
              <FontAwesomeIcon className="leaderboard-icon" icon="trophy" />
              Leaderboard
            </Link>
            <Link to="/game">
              <FontAwesomeIcon className="game-icon" icon="gamepad" />
              Game
            </Link>
            <a target="#" href="https://amazon.awsapps.com/workdocs/index.html#/document/adee40cf27ee2cc377df5a5072ab52e32ecf11d5b7bf8f87493fe03662cf74ad">
              <FontAwesomeIcon className="tutorial-icon" icon="question-circle" />
              Tutorial
            </a>
            <Link
              className="sign-out-button"
              to="/"
              onClick={() => Auth.signOut()}
            >
              <FontAwesomeIcon className="sign-out" icon="sign-out-alt" />
              SIGN OUT
            </Link>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default NavigationBar;
