import React, { useState, useEffect } from 'react';
import './StopWatch.css';

function Stopwatch(props) {
  const [seconds, setSeconds] = useState(0);
  const isActive = props.isActive;

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      props.setTimeToComplete(seconds)
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
    <div>
      <div className="time">{seconds} s</div>
    </div>
  );
}

export default Stopwatch;
